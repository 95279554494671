import { ArrowLeftIcon } from "@heroicons/react/outline";
import { UserCircleIcon } from "@heroicons/react/solid";
import { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { publicRequest } from "../axioMethod";


export default function ProfileComponent() {

  const [agent, setAgent] = useState([])

  const [success, setSuccess] = useState(false)
  const [err, setErr] = useState('')
  const [loading, setLoading] = useState(false)
  const [loadingProfile, setLoadingProfile] = useState(true)

  const name = useRef()
  const lga = useRef()
  const state = useRef()
  const email = useRef()
  const phone = useRef()
  const residentState = useRef()
  const address = useRef()

  // GET AGENT DATA
  useEffect(() => {

    const getUserInfo = async () => {
      
      setLoadingProfile(true);

      try {
        const res = await publicRequest.get('/agent/get/');
        setAgent(res.data);
        setLoadingProfile(false)
      } catch (error) {
        setLoadingProfile(false);
      }  

    }

    getUserInfo();

  }, [])

    // TIMEOUT FUNCTION
    const timeout = () => {
      setTimeout(() => {
        setErr('');
        setSuccess(false)
      }, 8000);
    } 
  
  
  // SUBMIT FORM FUNCTION
  const handleSubmit = async e => {

    e.preventDefault()

    setLoading(true)

    const newAgent = {
      name: name.current.value,
      lga: lga.current.value,
      state: state.current.value,
      phone: phone.current.value,
      email: email.current.value,
      address: address.current.value,
      residentState: residentState.current.value,
    }

    try {

      await publicRequest.put("/agent/update/", newAgent)
      setLoading(false)
      setSuccess(true)
      window.location.href = '#mainForm'
      setErr('')
      timeout()
      
    } catch (error) {

      setLoading(false)
      setSuccess(false)
      setErr(error.response.data)
      window.location.href = '#mainForm'
      timeout()
      console.log(error)

    }

  }

  return (
    <div id="mainForm" className="mt-10 w-full">
      <div className="flexCenter flex-col gap-3">
        <UserCircleIcon className="text-gray-500 h-20 md:h-28" />
        <h1 className="text-gray-600 font-bold text-xl md:text-2xl">WELCOME</h1>
      </div>
      {loadingProfile ? (
        <div className="flex center w-full flexCenter my-10">
          <img
            src={require("../images/load.gif")}
            className=" h-[300px] opacity-60 rounded-xl object-contain"
            alt=""
          />
        </div>
      ) : (
        <form
          onSubmit={handleSubmit}
          className="hidden flexCenter mx-auto w-full flex-col max-w-[800px] shadow-lg p-5 rounded-md border my-10 bg-gray-100 bg-opacity-60 gap-7"
        >
          {success && (
            <p className="text-white bg-green-500 p-4 rounded-md w-full font-bold text-xl text-center mt-5">
              Profile Updated Successfully
            </p>
          )}
          {err && (
            <p className="text-white bg-red-500 p-4 rounded-md w-full font-bold text-xl text-center mt-5">
              {err}
            </p>
          )}
          {/* FULL NAME */}
          <div className="w-full flex flex-col items-start justify-center gap-4">
            <p className="font-semibold text-md md:text-lg text-green-600">
              Name
            </p>
            <input
              ref={name}
              type="text"
              defaultValue={agent?.name}
              placeholder="Full Name"
              className="outline-none w-full max-w-[700px] border-b border-green-200 pb-2 bg-transparent text-gray-500"
            />
          </div>
          {/* STATE */}
          <div className="w-full flex flex-col items-start justify-center gap-4">
            <p className="font-semibold text-md md:text-lg text-green-600">
              State of Origin
            </p>
            <input
              ref={state}
              type="text"
              defaultValue={agent?.state}
              placeholder="State of Origin"
              className="outline-none w-full max-w-[700px] border-b border-green-200 pb-2 bg-transparent text-gray-500"
            />
          </div>
          {/* LOCAL GOVERNMENT */}
          <div className="w-full flex flex-col items-start justify-center gap-4">
            <p className="font-semibold text-md md:text-lg text-green-600">
              Local Government Area
            </p>
            <input
              ref={lga}
              type="text"
              defaultValue={agent?.lga}
              placeholder="L.G.A."
              className="outline-none w-full max-w-[700px] border-b border-green-200 pb-2 bg-transparent text-gray-500"
            />
          </div>
          {/* EMAIL */}
          <div className="w-full flex flex-col items-start justify-center gap-4">
            <p className="font-semibold text-md md:text-lg text-green-600">
              Email
            </p>
            <input
              ref={email}
              type="email"
              defaultValue={agent?.email}
              placeholder="Email"
              className="outline-none w-full max-w-[700px] border-b border-green-200 pb-2 bg-transparent text-gray-500"
            />
          </div>
          {/* PHONE NUMBER */}
          <div className="w-full flex flex-col items-start justify-center gap-4">
            <p className="font-semibold text-md md:text-lg text-green-600">
              Phone Number
            </p>
            <input
              ref={phone}
              type="text"
              defaultValue={agent?.phone}
              placeholder="Phone Number"
              className="outline-none w-full max-w-[700px] border-b border-green-200 pb-2 bg-transparent text-gray-500"
            />
          </div>
          {/* STATE OF RESIDENT */}
          <div className="w-full flex flex-col items-start justify-center gap-4">
            <p className="font-semibold text-md md:text-lg text-green-600">
              State of Residence
            </p>
            <select
              ref={residentState}
              className="outline-none w-full max-w-[700px] border-b border-green-200 pb-2 bg-transparent text-gray-500"
            >
              <option className="text-xs" value="All">
                {agent?.residentState}
              </option>
              <option className="text-xs" value="All">
                All States
              </option>
              <option className="text-xs" value="Abia">
                Abia
              </option>
              <option className="text-xs" value="Adamawa">
                Adamawa
              </option>
              <option className="text-xs" value="Akwa Ibom">
                Akwa Ibom
              </option>
              <option className="text-xs" value="Anambra">
                Anambra
              </option>
              <option className="text-xs" value="Bauchi">
                Bauchi
              </option>
              <option className="text-xs" value="Bayelsa">
                Bayelsa
              </option>
              <option className="text-xs" value="Benue">
                Benue
              </option>
              <option className="text-xs" value="Borno">
                Borno
              </option>
              <option className="text-xs" value="Cross River">
                Cross River
              </option>
              <option className="text-xs" value="Delta">
                Delta
              </option>
              <option className="text-xs" value="Ebonyi">
                Ebonyi
              </option>
              <option className="text-xs" value="Edo">
                Edo
              </option>
              <option className="text-xs" value="Ekiti">
                Ekiti
              </option>
              <option className="text-xs" value="Enugu">
                Enugu
              </option>
              <option className="text-xs" value="FCT">
                Federal Capital Territory
              </option>
              <option className="text-xs" value="Gombe">
                Gombe
              </option>
              <option className="text-xs" value="Imo">
                Imo
              </option>
              <option className="text-xs" value="Jigawa">
                Jigawa
              </option>
              <option className="text-xs" value="Kaduna">
                Kaduna
              </option>
              <option className="text-xs" value="Kano">
                Kano
              </option>
              <option className="text-xs" value="Katsina">
                Katsina
              </option>
              <option className="text-xs" value="Kebbi">
                Kebbi
              </option>
              <option className="text-xs" value="Kogi">
                Kogi
              </option>
              <option className="text-xs" value="Kwara">
                Kwara
              </option>
              <option className="text-xs" value="Lagos">
                Lagos
              </option>
              <option className="text-xs" value="Nasarawa">
                Nasarawa
              </option>
              <option className="text-xs" value="Niger">
                Niger
              </option>
              <option className="text-xs" value="Ogun">
                Ogun
              </option>
              <option className="text-xs" value="Ondo">
                Ondo
              </option>
              <option className="text-xs" value="Osun">
                Osun
              </option>
              <option className="text-xs" value="Oyo">
                Oyo
              </option>
              <option className="text-xs" value="Plateau">
                Plateau
              </option>
              <option className="text-xs" value="Rivers">
                Rivers
              </option>
              <option className="text-xs" value="Sokoto">
                Sokoto
              </option>
              <option className="text-xs" value="Taraba">
                Taraba
              </option>
              <option className="text-xs" value="Yobe">
                Yobe
              </option>
              <option className="text-xs" value="Zamfara">
                Zamfara
              </option>
            </select>
          </div>
          {/* PHONE NUMBER */}
          <div className="w-full flex flex-col items-start justify-center gap-4">
            <p className="font-semibold text-md md:text-lg text-green-600">
              Address
            </p>
            <input
              ref={address}
              type="text"
              defaultValue={agent?.address}
              placeholder="Current address"
              className="outline-none w-full max-w-[700px] border-b border-green-200 pb-2 bg-transparent text-gray-500"
            />
          </div>
          {/* SUBMIT BUTTON */}
          <button className="bg-gray-800 font-bold text-white p-5 rounded-md w-full my-10 block w-full mx-auto max-w-[750px] shadow-lg">
            {loading ? "Updating..." : "Update Profile"}
          </button>
        </form>
      )}
      <Link to="/admin">
        <div className="border-blue-300 pb-2 border-b my-10 w-full mx-auto max-w-[250px] flexCenter gap-3">
          <ArrowLeftIcon className="text-blue-500 h-5" />
          <p className="font-semibold text-blue-500 ">Return</p>
        </div>
      </Link>
    </div>
  );
}
