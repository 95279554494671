
import { MenuAlt3Icon, XIcon } from "@heroicons/react/outline";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { publicRequest } from "../axioMethod";


export default function SuperNavbar({mobile, setMobile}) {

  const [email, setEmail] = useState('');

  useEffect(() => {
    const getEmail = async () => {
      const res = await publicRequest.get('/super/auth/email');
      setEmail(res.data);
    };
    getEmail();
  }, []);
  
  return (
    <div className="top-0 w-full flex items-center justify-between p-2 sm:p-5 pb-4 bg-gray-100 shadow-md z-50 bg-opacity-90 fixed">
      <Link to="/super/page">
        <div className="flex items-center justify-center">
          <img
            src={require("../images/plogo.png")}
            className="h-20 object-contain"
            alt=""
          />
          <h1 className="russo text-gray-500 text-2xl font-semobild">State Admin</h1>
        </div>
      </Link>
      <div>
        {mobile ? (
          <XIcon
            onClick={() => setMobile(false)}
            className="h-10 text-gray-500 sm:hidden cursor-pointer"
          />
        ) : (
          <MenuAlt3Icon
            onClick={() => setMobile(true)}
            className="h-10 text-gray-500 sm:hidden cursor-pointer"
          />
        )}
      </div>
      <p className="text-pink-900 font-semibold text-sm md:text-lg absolute bottom-0 right-7">{email}</p>
    </div>
  );
}
