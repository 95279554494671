import { useRef, useState } from "react"

export default function PriceData({priceList, setPriceList, added, list}) {

    const [editMode, setEditMode] = useState(added)

    const price = useRef()
    const time = useRef()

    const removePrice = () => {

        const index = priceList.findIndex(
            p =>  (p.price == list?.price) && (p.time == list?.time)
        )

        priceList.splice(index, 1)

        setPriceList([...priceList])
    }
    
    const updatePrice = () => {
        
        
        if(editMode !== added){
            
            const index = priceList.findIndex(
                p =>  (p.price == list?.price) && (p.time == list?.time)
            )
                
            priceList[index] = { price: price.current.value, time: time.current.value }
            
            setPriceList([...priceList])

            setEditMode(added)
            
        } else if(added) {
            
            setEditMode(false)
            
        } else {
            
            if(!price.current.value || !time.current.value) return 
        
            setPriceList([...priceList, { price: price.current.value, time: time.current.value }])
            price.current.value = ''
            time.current.value = ''
        
        }

    }

    return (
        <div className="w-full flex gap-2 flex-col">
            <div className={`${editMode ? 'bg-gray-200' : added && "bg-gray-100"} rounded-md p-2 flexBetween gap-2 w-full max-w-[700px] border-b border-pink-200 pb-2`}>
                <input disabled={editMode} ref={price} type="number" defaultValue={list?.price || ''} className="disabled:cursor-not-allowed flex-1 outline-none w-full bg-transparent" placeholder="Enter product price" />
                <input disabled={editMode} ref={time} type="date" defaultValue={list?.time || ''} className="disabled:cursor-not-allowed outline-none bg-transparent" />
            </div>
            <div className="w-full max-w-[700px] flex gap-3 items-center justify-end">
            {added &&
                 <p onClick={removePrice} className='bg-pink-500 text-white py-1 font-semibold px-2 rounded-md text-xs cursor-pointer'>Remove</p>
                }
                <p onClick={updatePrice} className={`${added ? 'bg-white text-black border border-black' : 'text-white bg-gray-700'} py-1 font-semibold px-2 rounded-md text-xs cursor-pointer`}>{ !added ? 'Add Price' : editMode ? 'Edit Price' : 'Update'}
                </p>
            </div>
        </div>
    )
}
