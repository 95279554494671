import axios from "axios";
const BASE_URL = window.location.host.includes("localhost") ? "http://localhost:8800/api" : "http://www.prices.bonmaximus.com/api";
// const BASE_URL = "http://www.prices.bonmaximus.com/api";

export const publicRequest = axios.create({
  baseURL: BASE_URL,
  withCredentials: true
});

export const userRequest = axios.create({
  baseURL: BASE_URL,
  withCredentials: true
//   headers: { token: `Bearer ${user?.token}`}
});