
export default function NotFound(){

    return (
        

<svg xmlns="http://www.w3.org/2000/svg" width="338.642" height="287.675" viewBox="0 0 338.642 287.675">
  <defs>
    <filter id="file" x="153.613" y="69.675" width="178.908" height="209.369" filterUnits="userSpaceOnUse">
      <feOffset dy="2" input="SourceAlpha"/>
      <feGaussianBlur stdDeviation="3" result="blur"/>
      <feFlood flood-opacity="0.078"/>
      <feComposite operator="in" in2="blur"/>
      <feComposite in="SourceGraphic"/>
    </filter>
    <filter id="file-2" x="24.892" y="51.76" width="187.481" height="220.36" filterUnits="userSpaceOnUse">
      <feOffset dy="2" input="SourceAlpha"/>
      <feGaussianBlur stdDeviation="3" result="blur-2"/>
      <feFlood flood-opacity="0.078"/>
      <feComposite operator="in" in2="blur-2"/>
      <feComposite in="SourceGraphic"/>
    </filter>
    <filter id="file-2-2" x="92.244" y="0" width="157" height="208" filterUnits="userSpaceOnUse">
      <feOffset dy="2" input="SourceAlpha"/>
      <feGaussianBlur stdDeviation="3" result="blur-3"/>
      <feFlood flood-opacity="0.078"/>
      <feComposite operator="in" in2="blur-3"/>
      <feComposite in="SourceGraphic"/>
    </filter>
    <filter id="searfch-circle" x="174.244" y="117" width="128" height="129" filterUnits="userSpaceOnUse">
      <feOffset dy="2" input="SourceAlpha"/>
      <feGaussianBlur stdDeviation="3" result="blur-4"/>
      <feFlood flood-opacity="0.078"/>
      <feComposite operator="in" in2="blur-4"/>
      <feComposite in="SourceGraphic"/>
    </filter>
    <filter id="search-pen" x="261.774" y="205.565" width="76.868" height="76.868" filterUnits="userSpaceOnUse">
      <feOffset dy="2" input="SourceAlpha"/>
      <feGaussianBlur stdDeviation="3" result="blur-5"/>
      <feFlood flood-opacity="0.078"/>
      <feComposite operator="in" in2="blur-5"/>
      <feComposite in="SourceGraphic"/>
    </filter>
  </defs>
  <g id="empty-search-state" transform="translate(-429.756 -341)">
    <g id="files" transform="translate(463.649 348.133)">
      <g id="file-3">
        <g transform="matrix(1, 0, 0, 1, -33.89, -7.13)" filter="url(#file)">
          <g id="file-3-2" data-name="file" transform="matrix(0.97, 0.26, -0.26, 0.97, 205.41, 76.68)" fill="#fff" stroke="#e7eaf3" stroke-width="2">
            <rect width="122.278" height="165.355" rx="11" stroke="none"/>
            <rect x="1" y="1" width="120.278" height="163.355" rx="10" fill="none"/>
          </g>
        </g>
        <circle id="Ellipse_2" data-name="Ellipse 2" cx="11" cy="11" r="11" transform="translate(197.351 96.867)" fill="#e7eaf3"/>
        <rect id="Rectangle_12" data-name="Rectangle 12" width="34.741" height="6.042" transform="matrix(0.966, 0.259, -0.259, 0.966, 225.694, 107.366)" fill="#e7eaf3"/>
        <rect id="Rectangle_13" data-name="Rectangle 13" width="49.091" height="3.776" transform="matrix(0.966, 0.259, -0.259, 0.966, 223.107, 116.846)" fill="#e7eaf3"/>
      </g>
      <g id="file-2-3" data-name="file-2" transform="translate(0 51.627)">
        <g transform="matrix(1, 0, 0, 1, -33.89, -58.76)" filter="url(#file-2)">
          <g id="file-2-4" data-name="file-2" transform="matrix(0.97, -0.26, 0.26, 0.97, 33.89, 92.03)" fill="#fff" stroke="#e7eaf3" stroke-width="2">
            <rect width="128.554" height="175.053" rx="11" stroke="none"/>
            <rect x="1" y="1" width="126.554" height="173.053" rx="10" fill="none"/>
          </g>
        </g>
        <g id="content" transform="matrix(0.966, -0.259, 0.259, 0.966, 23.284, 51.485)">
          <rect id="Rectangle_2" data-name="Rectangle 2" width="34.741" height="6.042" transform="translate(27.651 1.642)" fill="#e7eaf3"/>
          <rect id="Rectangle_8" data-name="Rectangle 8" width="49.091" height="3.776" transform="translate(27.299 11.712)" fill="#e7eaf3"/>
          <rect id="Rectangle_3" data-name="Rectangle 3" width="88.364" height="3.776" transform="translate(0.827 32.234)" fill="#e7eaf3"/>
          <rect id="Rectangle_11" data-name="Rectangle 11" width="88.364" height="3.776" transform="translate(1.573 70.612)" fill="#e7eaf3"/>
          <rect id="Rectangle_4" data-name="Rectangle 4" width="88.364" height="3.776" transform="translate(0.827 42.053)" fill="#e7eaf3"/>
          <rect id="Rectangle_10" data-name="Rectangle 10" width="88.364" height="3.776" transform="translate(1.573 80.43)" fill="#e7eaf3"/>
          <rect id="Rectangle_5" data-name="Rectangle 5" width="67.217" height="3.776" transform="translate(0.827 51.871)" fill="#e7eaf3"/>
          <rect id="Rectangle_9" data-name="Rectangle 9" width="67.217" height="3.776" transform="translate(1.573 90.248)" fill="#e7eaf3"/>
          <rect id="Rectangle_6" data-name="Rectangle 6" width="40.028" height="12.084" transform="translate(0.527 120.936)" fill="#e7eaf3"/>
          <rect id="Rectangle_7" data-name="Rectangle 7" width="19.636" height="19.636" transform="translate(0 0)" fill="#e7eaf3"/>
        </g>
      </g>
      <g id="file-1" transform="translate(67.535 0)">
        <g transform="matrix(1, 0, 0, 1, -101.43, -7.13)" filter="url(#file-2-2)">
          <g id="file-4" data-name="file" transform="translate(101.24 7)" fill="#fff" stroke="#e7eaf3" stroke-width="2">
            <rect width="139" height="190" rx="11" stroke="none"/>
            <rect x="1" y="1" width="137" height="188" rx="10" fill="none"/>
          </g>
        </g>
        <g id="content-2" data-name="content" transform="translate(21.306 23.765)">
          <rect id="Rectangle_1" data-name="Rectangle 1" width="78" height="10" transform="translate(3.51 42.102)" fill="#e7eaf3"/>
          <rect id="Rectangle_2-2" data-name="Rectangle 2" width="96" height="4" transform="translate(3.51 57.102)" fill="#e7eaf3"/>
          <rect id="Rectangle_3-2" data-name="Rectangle 3" width="96" height="4" transform="translate(3.51 76.102)" fill="#e7eaf3"/>
          <rect id="Rectangle_4-2" data-name="Rectangle 4" width="96" height="4" transform="translate(3.51 87.102)" fill="#e7eaf3"/>
          <rect id="Rectangle_5-2" data-name="Rectangle 5" width="73" height="4" transform="translate(3.51 98.102)" fill="#e7eaf3"/>
          <rect id="Rectangle_6-2" data-name="Rectangle 6" width="43" height="13" transform="translate(3.51 129.102)" fill="#e7eaf3"/>
          <text id="_" data-name="?" transform="translate(12.51 25.102)" fill="#d4d9e8" font-size="35" font-family="Roboto-Medium, Roboto" font-weight="500"><tspan x="-8.511" y="0">?</tspan></text>
        </g>
      </g>
    </g>
    <g id="lines" transform="translate(433.338 355.22)">
      <line id="Line_2" data-name="Line 2" x1="14.839" y2="16.323" transform="translate(289.949 15.614)" fill="none" stroke="#d4d9e8" stroke-linecap="round" stroke-width="4"/>
      <line id="Line_3" data-name="Line 3" x2="14.839" y2="16.323" transform="translate(36.202 0)" fill="none" stroke="#d4d9e8" stroke-linecap="round" stroke-width="4"/>
      <line id="Line_4" data-name="Line 4" y1="16.323" x2="7.42" transform="translate(7.663 172.704) rotate(28)" fill="none" stroke="#d4d9e8" stroke-linecap="round" stroke-width="4"/>
      <line id="Line_5" data-name="Line 5" y1="16.323" x2="7.42" transform="translate(169.847 254.302) rotate(-9)" fill="none" stroke="#d4d9e8" stroke-linecap="round" stroke-width="4"/>
    </g>
    <g id="search" transform="translate(613.131 465.318)">
      <g transform="matrix(1, 0, 0, 1, -183.37, -124.32)" filter="url(#searfch-circle)">
        <g id="searfch-circle-2" data-name="searfch-circle" transform="translate(183.24 124)" fill="#fff" stroke="#d4d9e8" stroke-width="12">
          <ellipse cx="55" cy="55.5" rx="55" ry="55.5" stroke="none"/>
          <ellipse cx="55" cy="55.5" rx="49" ry="49.5" fill="none"/>
        </g>
      </g>
      <g transform="matrix(1, 0, 0, 1, -183.37, -124.32)" filter="url(#search-pen)">
        <path id="search-pen-2" data-name="search-pen" d="M0,0,48.969,48.969" transform="translate(275.72 217.52)" fill="none" stroke="#d4d9e8" stroke-linecap="round" stroke-width="7"/>
      </g>
      <g id="x" transform="translate(-613.131 -465.318)">
        <line id="Line_6" data-name="Line 6" x1="38.3" y2="38.3" transform="translate(650.2 500.2)" fill="none" stroke="#e7eaf3" stroke-linecap="round" stroke-width="5"/>
        <line id="Line_7" data-name="Line 7" x2="38.3" y2="38.3" transform="translate(650.2 500.2)" fill="none" stroke="#e7eaf3" stroke-linecap="round" stroke-width="5"/>
      </g>
    </g>
  </g>
</svg>

    )
}